import { Toolbar } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import Page from "../components/Page";
import ApplicationContext from "../context";
import { getInvoiceById } from "../context/APIRequest";
import InvoicePreview from "./InvoiceView";

const InvoiceById = () => {
  const { params: { id } } = useMatch('/list-invoice/:id');
  const { callAPI, setLoading } = useContext(ApplicationContext);
  const callAPIRef = useRef(callAPI);
  const loading = useRef(setLoading);
  const [data, setData] = useState();
  useEffect(() => {
    const getInvoice = async (id) => {
      const local = localStorage.getItem(`invoice-${id}`);
      if (local) {
        setData(JSON.parse(local));
        return;
      }
      loading.current(true);
      const [result, error] = await callAPIRef.current(getInvoiceById, id);
      if (!error) {
        setData(result);
        localStorage.setItem(`invoice-${id}`, JSON.stringify(result));
      }
      loading.current(false);
    }
    getInvoice(id);
  }, [id])

  return <Page title='Preview'>
    <Toolbar />
    <InvoicePreview data={data} />
  </Page>
}
export default InvoiceById