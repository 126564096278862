// material
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = 'test', ...other }, ref) => {
  return (
    <Box ref={ref} {...other} mb={{ xs: 6, sm: 0 }}>
      <Helmet>
        <title>{title} | {'Invoice Code Crewz'} </title>
      </Helmet>
      {children}
    </Box>
  )
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
