import {
  Button, Card, CardActions, CardContent, CardHeader, Icon, IconButton, Stack, Table, TableBody, TableCell,
  TableHead, TableRow, TextField, Toolbar
} from "@mui/material";
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import Page from "../components/Page";
import ApplicationContext from "../context";
import { getInvoice, sendInvoice } from "../context/APIRequest";
import InvoicePreview, { getHtml } from "./InvoiceView";
const fields = [
  {
    name: 'clientName',
    label: 'Client Name',
    placeholder: 'Client Name',
  },
  {
    name: 'clientAddress',
    label: 'Client Address',
    placeholder: 'Client Address',
  },
  {
    name: 'clientEmail',
    label: 'Client Email',
    placeholder: 'Client Email',
    disabled: true,
  },
  {
    name: 'clientPhone',
    label: 'Client Phone',
    placeholder: 'Client Phone',
  }
]
const validationSchema = yup.object({
  emailTo: yup.string().required("Email is required"),
  emailSubject: yup.string().required("Subject is required"),
  clientName: yup.string().required('Client Name is required'),
  clientAddress: yup.string().required('Client Address is required'),
  clientEmail: yup.string().email('Email Must be a valid').required('Client Email is required'),
  clientPhone: yup.string().required('Client Phone is required'),
  details: yup.array().of(yup.object({
    itemName: yup.string().required('Item Name is required'),
    itemPrice: yup.number().required('Item Price is required'),
    itemQty: yup.number().required('Item Quantity is required'),
  }))
});
const initialValues = {
  emailTo: '',
  emailSubject: '',
  clientName: '',
  clientAddress: '',
  clientEmail: '',
  clientPhone: '',
  details: [
    { itemName: '', itemPrice: 0, itemQty: 1 }
  ]
};
const Dashboard = ({ children, ...props }) => {
  const { setup, callAPI, setAlert, setLoading } = useContext(ApplicationContext);
  const callAPIRef = useRef(callAPI);
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      const [, error] = await callAPI(sendInvoice, { ...values, html: getHtml(setup, { ...values, invoiceNo }) });
      if (!error) {
        resetForm(initialValues)
        setAlert({ open: true, type: 'success', message: 'Invoice Send Successfully' });
        setI(i => i + 1);
      }
      setLoading(false)

    },
  });

  const { handleSubmit, setFieldValue, getFieldProps, errors, touched, values, resetForm } = formik;
  const [invoiceNo, setInvoiceNo] = useState(0);
  const [i, setI] = useState(0);
  useEffect(() => {
    const getInvoiceNo = async () => {
      const [result, error] = await callAPIRef.current(getInvoice);
      if (!error) {
        setInvoiceNo(result);
      }
    }
    getInvoiceNo()
  }, [i])
  return <Page title='Invoice'>
    <Stack {...props} direction={{ xs: 'column', lg: 'row' }} spacing={2} padding={1}>
      <Stack flexGrow={1}>
        <Toolbar />
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Invoice Details" />
            <CardContent>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    size='small'
                    label="Email to"
                    placeholder="Client Emails"
                    name="emailTo"
                    error={errors.emailTo && touched.emailTo}
                    helperText={touched.emailTo && errors.emailTo}
                    {...getFieldProps('emailTo')} />
                  <TextField
                    fullWidth
                    size='small'
                    label="Email Subject"
                    placeholder="Enter Subject"
                    error={errors.emailSubject && touched.emailSubject}
                    helperText={touched.emailSubject && errors.emailSubject}
                    {...getFieldProps('emailSubject')} />
                </Stack>
                <Stack direction={{ xs: 'column', xl: 'row' }} spacing={2}>
                  <Card xs={{ minWidth: 500 }}>
                    <CardHeader title="Client Details" />
                    <CardContent>
                      <Stack spacing={2}>
                        {fields.map(({ name, label, placeholder }) => {
                          return <TextField
                            key={name}
                            size='small'
                            label={label}
                            error={touched[name] && errors[name]}
                            helperText={touched[name] && errors[name]}
                            placeholder={placeholder}
                            {...getFieldProps(name)}
                          />
                        })}
                      </Stack>
                    </CardContent>
                  </Card>
                  <Card sx={{ flexGrow: 1 }}>
                    <CardHeader title="Item Details" />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Item Name</TableCell>
                            <TableCell numeric>Price</TableCell>
                            <TableCell numeric>Quantity</TableCell>
                            <TableCell numeric>Total</TableCell>
                            <TableCell numeric></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.details?.map(({ itemPrice, itemQty }, index) => {
                            return (
                              <TableRow key={`item-${index}`}>
                                <TableCell component="th" scope="row">
                                  <TextField
                                    size='small'
                                    label='Item Name'
                                    error={touched.details?.[index]?.itemName && errors.details?.[index]?.itemName}
                                    helperText={touched.details?.[index]?.itemName && errors.details?.[index]?.itemName}
                                    {...getFieldProps(`details[${index}].itemName`)}
                                  />
                                </TableCell>
                                <TableCell numeric>
                                  <TextField
                                    size='small'
                                    label='Price'
                                    type='number'
                                    error={touched.details?.[index]?.itemPrice && errors.details?.[index]?.itemPrice}
                                    helperText={touched.details?.[index]?.itemPrice && errors.details?.[index]?.itemPrice}
                                    {...getFieldProps(`details[${index}].itemPrice`)}
                                  />
                                </TableCell>
                                <TableCell numeric>
                                  <TextField
                                    size='small'
                                    label='Quantity'
                                    error={touched.details?.[index]?.itemQty && errors.details?.[index]?.itemQty}
                                    type='number'
                                    helperText={touched.details?.[index]?.itemQty && errors.details?.[index]?.itemQty}
                                    {...getFieldProps(`details[${index}].itemQty`)}
                                  />
                                </TableCell>
                                <TableCell numeric>
                                  {itemPrice * itemQty}
                                </TableCell>
                                <TableCell numeric>
                                  <IconButton color='error'
                                    tabIndex={-1}
                                    onClick={e => {
                                      const details = [...values.details];
                                      details.splice(index, 1);
                                      if (details.length === 0) {
                                        details.push({ itemName: '', itemPrice: 0, itemQty: 1 });
                                      }
                                      setFieldValue('details', details);
                                    }}>
                                    <Icon>delete</Icon>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardActions>
                      <Button startIcon={<Icon>add</Icon>} onClick={e => {
                        e.preventDefault();
                        setFieldValue('details', [...values.details, { itemName: '', itemPrice: 0, itemQty: 1 }]);
                      }}>Add Item</Button>
                    </CardActions>
                  </Card>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Button type="reset" onClick={e => resetForm(initialValues)}>Reset</Button>
              <Button type="submit">Send Email</Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
      <InvoicePreview
        sx={{ minHeight: 800, minWidth: { xs: 0, md: 650 } }}
        data={{ ...values, invoiceNo }} />
    </Stack>
  </Page>
}

export default Dashboard;