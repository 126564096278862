import { Box, Button, Stack, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import Image404 from './Image404';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'grid',
  maxHeight: '100vh',
}));

// ----------------------------------------------------------------------

export default function Page404({ home = '/' }) {
  return (
    <RootStyle title="404 Page Not Found" display='grid' postition={'fixed'}
      top={0} left={0} right={0} bottom={0}>
      <MotionContainer initial="initial" open sx={{ placeSelf: 'center' }}>
        <Stack alignItems={'center'} spacing={2} >
          <motion.div variants={varBounceIn}>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for.<br /> Perhaps you’ve mistyped the URL?
            Be sure to check your spelling.
          </Typography>

          <motion.div variants={varBounceIn}>
            <Box
              component={Image404}
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
          </motion.div>

          <Button to={home} size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </Stack>
      </MotionContainer>
    </RootStyle>
  );
}
