import { Card, CardContent, CardHeader } from "@mui/material"
import { format } from "date-fns";
import { useContext, useEffect, useRef } from "react";
import ApplicationContext from "../context";
import { Iframe, templete } from "./Setup"

const InvoicePreview = ({ data, ...props }) => {
  const iframe = useRef(null);
  const { setup } = useContext(ApplicationContext);
  useEffect(() => {
    const setDate = () => {
      if (setup && setup[0] && data) {
        if (iframe.current) {
          const html = getHtml(setup, data);
          iframe.current.src = "data:text/html;charset=utf-8," + encodeURIComponent(html);
        }
      }
    }
    const timeout = setTimeout(setDate, 1000);
    return () => clearTimeout(timeout);
  }, [setup, data])
  return <Card {...props}>
    <CardHeader title="Invoice Preview" />
    <CardContent>
      <Iframe ref={iframe} minWidth={{ xs: 0, md: 650 }}>
      </Iframe>
    </CardContent>
  </Card>
}
export default InvoicePreview

export const getHtml = (setup, data) => {
  const { emailCss, emailBody, emailHeader, emailFooter } = setup[0];
  const {
    clientName, clientAddress, clientEmail, clientPhone, details, invoiceNo = 0
  } = data;
  const subTotal = details?.reduce((acc, { itemPrice, itemQty }) => acc + (itemPrice || 0) * (itemQty || 0), 0);

  return templete
    .replace('{{css}}', emailCss)
    .replace('{{header}}', emailHeader)
    .replace("{{footer}}", emailFooter)
    .replace('{{ownerName}}', setup[2].ownerName)
    .replace('{{ownerAddress}}', setup[2].ownerAddress)
    .replaceAll('{{ownerEmail}}', setup[2].ownerEmail)
    .replace('{{ownerPhone}}', setup[2].ownerPhone)
    .replace('{{abn}}', setup[2].abn)
    .replace('{{bankName}}', setup[2].bankName)
    .replace('{{bankNo}}', setup[2].bankAccount)
    .replace('{{bsb}}', setup[2].bankBSB)
    .replace('{{bankHolder}}', setup[2].bankAccountName)
    .replace('{{gst}}', setup[2].gst || '10')
    .replace('{{invoiceDate}}', format(new Date(), setup[2].dateFormat))
    .replace('{{logo}}', setup[2].logo)
    .replace('{{clientName}}', clientName || 'Client Name')
    .replace('{{clientAddress}}', clientAddress || 'Client Address')
    .replaceAll('{{clientEmail}}', clientEmail || 'Client Email')
    .replace('{{clientPhone}}', clientPhone || 'Client Phone')
    .replace('{{body}}', details?.map(({ itemName, itemPrice = 0, itemQty = 1 }) => {
      return emailBody
        .replace('{{itemName}}', itemName || 'Item Name')
        .replace('{{itemPrice}}', itemPrice?.toFixed(2) || '0.00')
        .replace('{{itemQty}}', itemQty)
        .replace('{{itemTotal}}', ((itemPrice || 0) * (itemQty || 1)).toFixed(2))
    }).join(''))
    .replace('{{itemSubTotal}}', subTotal.toFixed(2))
    .replaceAll('{{currency}}', setup[2].currency)
    .replace('{{invoiceNo}}', String(invoiceNo).padStart(4, '0'))
    .replace('{{gstAmount}}', (subTotal * (setup[2].gst || 10) / 100).toFixed(2))
    .replace('{{totalPrice}}', (subTotal * (1 + (setup[2].gst || 10) / 100)).toFixed(2))
}