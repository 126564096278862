import { Avatar, Box, Button, Card, CardContent, CardHeader, Icon, Link, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import Page from "../components/Page";
import ApplicationContext from "../context";
import { loginAPI } from "../context/APIRequest";

const Login = ({ children, ...props }) => {
  const { callAPI, setAuth, setLoading } = useContext(ApplicationContext)
  const [error, setError] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const [result, error] = await callAPI(loginAPI, {
      username: data.get('email'),
      password: data.get('password'),
    });
    if (!error) {
      setAuth(auth => {
        localStorage.setItem('token', result);
        return result;
      });
    } else {
      setError('Username or Password is incorrect');
      setLoading(false);
    }
  };

  return <Page title='Login'>
    <Box display="grid" height="100vh">
      <Card sx={{ placeSelf: 'center' }}>
        <CardHeader title="Login" />
        <CardContent>
          <Box
            mt={8}
            display="flex"
            flexDirection="column"
            alignItems="center" >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <Icon>lock</Icon>
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              {error && <Typography color="error">{error}</Typography>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </CardContent>
      </Card>
    </Box>
  </Page>
}

export default Login;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://codecrewz.com.au/">
        Code Crewz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}