import { Alert, Snackbar } from "@mui/material";
import { createContext, useEffect, useRef, useState } from "react";
import Loadder from "../components/Loadder";
import ThemeConfig from "../theme";
import { getSetup, getUser } from "./APIRequest";

const ApplicationContext = createContext(null);
export default ApplicationContext;
export function ContextProvider({ children }) {
  const [loading, setLoading] = useState();
  const [alert, setAlert] = useState();
  const [user, setUser] = useState();
  const [auth, setAuth] = useState(localStorage.getItem("token"));

  const handelError = (result, status) => {
    if (status === 403) {
      setAuth(null)
      setUser(null)
    }
    setAlert({ open: true, type: 'error', message: result.data })
  }
  const callAPI = async (API, data, status = [422, 404]) => {
    const error = [null, 'Network Error!!!'];
    try {
      const fetchResult = await API(data);
      const result = await fetchResult.json();
      if (result.status) {
        error[0] = result.data;
        delete error[1]
      } else if (!status.includes(fetchResult.status)) {
        handelError(result);
      } else {
        error[1] = result.data || result.errorCode;
      }
    } catch (error) {
      handelError({ data: 'Network Error!!!' });
    }
    return error;

  }
  const callAPIRef = useRef(callAPI);
  const { open = false, type, message } = alert || {};
  const [setup, setSetup] = useState([]);
  useEffect(() => {
    const getDetails = async () => {
      const setup = localStorage.getItem("setup");
      if (setup) {
        try {
          setSetup(JSON.parse(setup));
          setLoading(false);
        } catch (err) { }
      }
      const [result, error] = await callAPIRef.current(getSetup);
      if (!error) {
        setSetup(result)
        localStorage.setItem("setup", JSON.stringify(result));
      }
      setLoading(false);
    }
    if (auth) {
      setLoading(true);
      const fetchUser = async () => {
        const [result, error] = await callAPIRef.current(getUser);
        if (!error) {
          if (result.userRole === 'ROLE_CLIENT') {
            await getDetails()
            setUser(result);
            return
          }
        }
        setAuth()
        localStorage.removeItem('token')
        setLoading(false);
        setAlert({ open: true, type: 'error', message: 'You are not authorized to access this page' })
      }
      fetchUser();
    }
  }, [auth]);
  const handleClose = _ => setAlert();
  const logout = async () => {
    setAuth(null)
    localStorage.clear()
    setUser(null)
  }
  return (
    <ApplicationContext.Provider value={{
      setAlert, auth, user, callAPI, setAuth, setLoading, setup, logout
    }}>
      <ThemeConfig>
        {children}
        {loading && <Loadder />}
        <Snackbar open={open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={type}>{message}</Alert>
        </Snackbar>
      </ThemeConfig>
    </ApplicationContext.Provider>
  )
}