import { useContext, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import ApplicationContext from './context';
import ChangePassword from './pages/ChangePassword';
import Dashboard from './pages/Dashboard';
import DashboardLayout from './pages/DashboardLayout';
import InvoiceById from './pages/InvoiceById';
import InvoiceList from './pages/InvoiceList';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import Setup from './pages/Setup';

// ----------------------------------------------------------------------
export default function Router() {
  const { auth } = useContext(ApplicationContext);
  const [routes, setRoutes] = useState({});
  useEffect(() => {
    setRoutes(auth ? {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Dashboard /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/list-invoice', element: <InvoiceList /> },
        { path: '/list-invoice/:id', element: <InvoiceById /> },
        { path: '/setup', element: <Setup /> },
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: '*', element: <Page404 /> }
      ]
    } : {
      path: '/',
      children: [
        { path: '/', element: <Login /> },
        { path: '*', element: <Page404 /> }
      ]
    });
  }, [auth])

  return useRoutes([
    routes
  ]);
}
