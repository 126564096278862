import {
  Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, Stack, TextField
} from '@mui/material';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import * as yup from 'yup';
import Page from '../components/Page';
import ApplicationContext from '../context';
import { emailSetup, invoiceSetup, ownerSetup } from '../context/APIRequest';



const Setup = ({ children, ...props }) => {
  const isAdmin = localStorage.getItem('isAdmin');
  return <Page title='Company Details'>
    <Stack {...props} spacing={2} padding={1}>
      <Stack spacing={2} padding={1} direction={'row'}>
        <OwnerSetup sx={{ flexGrow: 1 }} />
        {isAdmin && <EmailSetup sx={{ flexGrow: 1 }} />}
      </Stack>
      {isAdmin && <InvoiceSetup sx={{ flexGrow: 1 }} />}
    </Stack>
  </Page>
}

export default Setup;

const ownerFields = [
  { name: 'ownerName', label: 'Name', placeHolder: 'Enter your name' },
  { name: 'ownerEmail', label: 'Email', placeHolder: 'Enter your email' },
  { name: 'ownerAddress', label: 'Address', placeHolder: 'Enter your address' },
  { name: 'ownerPhone', label: 'Phone', placeHolder: 'Enter your phone' },
  { name: 'abn', label: 'ABN', placeHolder: 'Enter your ABN' },
  { name: 'bankName', label: 'Bank Name', placeHolder: 'Enter your bank name' },
  { name: 'bankAccount', label: 'Bank Account', placeHolder: 'Enter your bank account' },
  { name: 'bankBSB', label: 'Bank BSB', placeHolder: 'Enter your bank BSB' },
  { name: 'bankAccountName', label: 'Bank Account Name', placeHolder: 'Enter your bank account name' },
  { name: 'currency', label: 'Currency', placeHolder: 'Enter your Currency' },
  { name: 'logo', label: 'Logo', placeHolder: 'Enter your Logo' },
  { name: 'gst', label: 'gst', placeHolder: 'Enter your gst', type: 'number' },
  { name: 'dateFormat', label: 'Date Format', placeHolder: 'Enter your gst', type: 'number' },
]
const OwnerSetup = ({ children, ...props }) => {
  const { callAPI, setAlert, setLoading, setup } = useContext(ApplicationContext);
  const formik = useFormik({
    initialValues: setup[2] ? setup[2] : {
      ownerName: '',
      ownerEmail: '',
      ownerAddress: '',
      ownerPhone: '',
      abn: '',
      bankName: '',
      bankAccount: '',
      bankBSB: '',
      bankAccountName: '',
      currency: 'A$',
      logo: '',
      dateFormat: 'DD/MM/YYYY',
    },
    validationSchema: yup.object({
      ownerName: yup.string().required('Owner Name is required'),
      ownerEmail: yup.string().required('Owner Email is required'),
      ownerAddress: yup.string().required('Owner Address is required'),
      ownerPhone: yup.string().required('Owner Phone is required'),
      abn: yup.string().required('ABN is required'),
      bankName: yup.string().required('Bank Name is required'),
      bankAccount: yup.string().required('Bank Account is required'),
      bankBSB: yup.string().required('Bank BSB is required'),
      bankAccountName: yup.string().required('Bank Account Name is required'),
      currency: yup.string().required('Currency is required'),
      logo: yup.string().required('Logo is required'),
      gst: yup.number().required('GST is required'),
    }),
    validate: (values) => {
      localStorage.setItem('owner', JSON.stringify(values));
      return {};
    },
    onSubmit: async (values) => {
      setLoading(true)
      const [, error] = await callAPI(ownerSetup, values);
      if (!error) {
        setAlert({ open: true, type: 'success', message: 'Owner Setup Successfully' });
      }
      setLoading(false)
    },
  });
  const { handleSubmit, getFieldProps, errors, touched } = formik;
  return <Card {...props}>
    <CardHeader title='Company Details' />
    <form onSubmit={handleSubmit}>
      <CardContent>
        <Stack spacing={2}>
          {
            ownerFields.map(({ name, type, ...other }) => {
              if (type === 'checkbox')
                return <FormControlLabel
                  control={<Checkbox
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    size='small' />} {...other}
                  {...getFieldProps(name)}
                />

              return <TextField
                key={name}
                size='small'
                {...other}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                {...getFieldProps(name)}
              />
            })
          }
        </Stack>
      </CardContent>
      <CardActions>
        <Button size='small' type='submit'>Save</Button>
      </CardActions>
    </form>
  </Card>
}

const emailFields = [
  { name: 'username', label: 'Username', placeHolder: 'Enter your email username' },
  { name: 'password', label: 'Password', placeHolder: 'Enter your smtp password' },
  { name: 'hostUrl', label: 'Host', placeHolder: 'Enter your smtp host' },
  { name: 'emailPort', label: 'Port', placeHolder: 'Enter your smtp port' },
  { name: 'emailFrom', label: 'From', placeHolder: 'Enter your email from' },
  { name: 'emailSsl', label: 'SSL / TLS', type: 'checkbox' },
  { name: 'emailAuth', label: 'Auth', type: 'checkbox' },
  { name: 'starttls', label: 'Starttls', type: 'checkbox' },
]
const EmailSetup = ({ children, ...props }) => {
  const { callAPI, setAlert, setLoading, setup } = useContext(ApplicationContext);
  const formik = useFormik({
    initialValues: setup[1] ? setup[1] : {
      username: '',
      password: '',
      emailFrom: '',
      hostUrl: '',
      emailPort: 465,
      emailAuth: true,
      starttls: true,
      emailSsl: true,
    },
    validate: (values) => {
      localStorage.setItem('email', JSON.stringify(values));
      return {};
    },
    validationSchema: yup.object({
      username: yup.string().required('Client Name is required'),
      password: yup.string().required('Client Address is required'),
      hostUrl: yup.string().required('Host is required'),
      emailPort: yup.number().required('Port is required'),
      emailFrom: yup.string().required('From is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      const [, error] = await callAPI(emailSetup, values);
      if (!error) {
        setAlert({ open: true, type: 'success', message: 'Email Setup Successfully' });
      }
      setLoading(false)
    },
  });
  const { handleSubmit, getFieldProps, errors, setFieldValue, values, touched } = formik;
  return <Card {...props}>
    <CardHeader title='Email Setup' />
    <form onSubmit={handleSubmit}>
      <CardContent>
        <Stack spacing={2}>
          {
            emailFields.map(({ name, type, ...other }) => {
              if (type === 'checkbox')
                return <FormControlLabel
                  control={<Checkbox
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    size='small' />} {...other}
                  {...getFieldProps(name)}
                  onChange={(e) => { setFieldValue(name, e.target.checked) }}
                  checked={values[name]}
                />

              return <TextField
                key={name}
                size='small'
                {...other}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                {...getFieldProps(name)}
              />
            })
          }
        </Stack>
      </CardContent>
      <CardActions>
        <Button size='small' type='submit'>Save</Button>
      </CardActions>
    </form>
  </Card>
}
const invoiceFields = [
  { name: 'emailHtml', label: 'Email HTML', type: 'textarea', placeHolder: 'Enter your email html', disabled: true },
  { name: 'emailHeader', label: 'Email Header', type: 'textarea', placeHolder: 'Enter your email header' },
  { name: 'emailBody', label: 'Email Body', type: 'textarea', placeHolder: 'Enter your email body' },
  { name: 'emailFooter', label: 'Email Footer', type: 'textarea', placeHolder: 'Enter your email footer' },
  { name: 'emailCss', label: 'Email CSS', type: 'textarea', placeHolder: 'Enter your email css' },
]
export const templete = `<html>
<head>
  <title>Invoice</title>
  <style>{{css}}</style>
</head>
<body>
  {{header}}
  {{body}}
  {{footer}}
</body>
</html>`;
const InvoiceSetup = ({ children, ...props }) => {
  const { setup, callAPI, setAlert, setLoading } = useContext(ApplicationContext);
  const formik = useFormik({
    initialValues: setup[0] ? {
      ...setup[0],
      emailHtml: templete,
    } : {
      emailHtml: templete,
      emailHeader: '',
      emailBody: '',
      emailFooter: '',
      emailCss: '',
    },
    validationSchema: yup.object({
      emailHtml: yup.string().required('emailHtml is required'),
      emailHeader: yup.string().required('emailHeader is required'),
      emailBody: yup.string().required('emailBody is required'),
      emailFooter: yup.string().required('emailFooter is required'),
      emailCss: yup.string().required('emailCss is required'),
    }),
    validate: (values) => {
      localStorage.setItem('invoice', JSON.stringify(values));
      return {};
    },
    onSubmit: async (values) => {
      setLoading(true)
      const [, error] = await callAPI(invoiceSetup, values);
      if (!error) {
        setAlert({ open: true, type: 'success', message: 'Invoice Setup Successfully' });
      }
      setLoading(false)
    },
  });
  const { handleSubmit, getFieldProps, errors, touched, values } = formik;
  const iframe = useRef(null);
  useEffect(() => {
    if (iframe) {
      iframe.current.src = "data:text/html;charset=utf-8," + encodeURIComponent(
        values.emailHtml
          .replace('{{css}}', values.emailCss)
          .replace('{{header}}', values.emailHeader)
          .replace("{{body}}", values.emailBody)
          .replace("{{footer}}", values.emailFooter)
      );
    }
  }, [values])
  return <Card {...props}>
    <CardHeader title='Invoice Setup' />
    <CardContent>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Card sx={{ flexGrow: 1 }}>
          <CardHeader title="Invoice Preview" />
          <form onSubmit={handleSubmit} >
            <CardContent>
              <Stack spacing={2}>
                {
                  invoiceFields.map(({ name, ...other }) => <TextField
                    key={name}
                    multiline
                    size='small'
                    rows={10}
                    {...other}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    {...getFieldProps(name)}
                  />)
                }

              </Stack>
            </CardContent>
            <CardActions>
              <Button size='small' type='submit'>Save</Button>
            </CardActions>
          </form>
        </Card>
        <Card sx={{ minHeight: 800, minWidth: 300 }}>
          <CardHeader title="Invoice Preview" />
          <CardContent>
            <Iframe ref={iframe}>
            </Iframe>
          </CardContent>
        </Card>
      </Stack>
    </CardContent>
  </Card>
}

export const Iframe = styled('iframe')(({ theme, minWidth }) => ({
  backgroundColor: 'white',
  overflowY: 'auto',
  whiteSpace: 'pre',
  minHeight: 800, minWidth,
  width: '100%',
  right: 0,
}));