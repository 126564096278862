import {
  Card,
  CardContent,
  CardHeader,
  Icon, IconButton,
  LinearProgress,
  Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow,
  Toolbar, Tooltip
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import Page from "../components/Page";
import ApplicationContext from "../context";
import { getInvoiceById, getInvoiceList } from "../context/APIRequest";


const columns = [
  { id: 'invoiceNo', label: 'Invoice No', minWidth: 80 },
  { id: 'clientName', label: 'Client Name', minWidth: 100 },
  { id: 'clientEmail', label: 'Email', minWidth: 100 },
  {
    id: 'invoiceDate', label: 'Invoice Date', maxWidth: 100, custom: ({ invoiceDate }) => {
      return new Date(invoiceDate).toLocaleDateString();
    }
  },
]
const InvoiceList = ({ ...props }) => {
  const { callAPI, setLoading } = useContext(ApplicationContext);
  const callAPIRef = useRef(callAPI);
  const [invoiceList, setInvoiceList] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [content, setContent] = useState({ total: 0, totalPages: 0 });
  const loading = useRef(setLoading);
  useEffect(() => {
    const invoiceList = async () => {
      loading.current(true);
      const [result, error] = await callAPIRef.current(getInvoiceList, { page, size, sort: 'invoiceNo,desc' });
      if (!error) {
        setContent({ total: result.totalElements, totalPages: result.totalPages });
        setInvoiceList(result.content);
      }
      loading.current(false);
    }
    invoiceList();
  }, [page, size])
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(+event.target.value);
    setPage(0);
  };

  return <Page {...props} title='Invoice List'>
    <Toolbar />
    <Card sx={{ overflow: 'hidden' }}>
      <CardHeader title='Invoice List' />
      {invoiceList.length === 0 ? <CardContent>
        No Invoice Found
      </CardContent>
        : <>
          {/* <Toolbar>
          <Typography>Filters</Typography>

          </Toolbar> */}
          <TableContainer sx={{ minHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell width={60} />
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceList.map((row) => {
                  return (<Row row={row} />);
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={content.total}
            rowsPerPage={size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      }

    </Card>
  </Page>
}
export default InvoiceList

const Row = ({ row }) => {
  const { callAPI } = useContext(ApplicationContext);
  const callAPIRef = useRef(callAPI);
  const [data, setData] = useState(row);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getInvoice = async (id) => {
      const local = localStorage.getItem(`invoice-${id}`);
      if (local) {
        setData(JSON.parse(local));
        setLoading(false);
        return;
      }
      const [result, error] = await callAPIRef.current(getInvoiceById, id);
      if (!error) {
        setData(result);
        localStorage.setItem(`invoice-${id}`, JSON.stringify(result));
      }
      setLoading(false);
    }
    getInvoice(row.id);
  }, [row])
  return <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
    {loading ? <TableCell colSpan={5} ><LinearProgress /></TableCell> : <>
      {columns.map((column) => {
        const value = data[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.custom ? column.custom(data) : value}
          </TableCell>
        );
      })}
      <TableCell >
        <Tooltip title="View">
          <IconButton LinkComponent={Link} to={`/list-invoice/${row.id}`}>
            <Icon>visibility</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
    </>}
  </TableRow>
}