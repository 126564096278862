import { Button, Card, CardActions, CardContent, CardHeader, Icon, IconButton, InputAdornment, Stack, TextField, Toolbar } from "@mui/material"
import { useFormik } from "formik"
import { useContext, useState } from "react"
import * as Yup from "yup"
import Page from "../components/Page"
import ApplicationContext from "../context"
import { changepassword } from "../context/APIRequest"
const passField = [
  {
    name: "oldPassword",
    label: "Old Password",
    placeHolder: "Enter Old Password",
  },
  {
    name: "newPassword",
    label: "New Password",
    placeHolder: "Enter New Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Enter Confirm Password",
  },
]
const initialValues={
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
}
const ChangePassword = ({ children, ...props }) => {
  const { callAPI, setAlert, setLoading } = useContext(ApplicationContext)
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string().required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const [, error] = await callAPI(changepassword, values);
      if (!error) {
        resetForm(initialValues)
        setAlert({ open: true, type: 'success', message: 'Password Change Successful' })
      }
      setLoading(false);
    }
  })
  const { handleSubmit, errors, touched, getFieldProps } = formik
  return <Page {...props}>
    <Toolbar />
    <form onSubmit={handleSubmit}>
      <Card sx={{ maxWidth: 400, margin: 1 }}>
        <CardHeader title="Change Password" />
        <CardContent>
          <Stack spacing={2}>
            {
              passField.map(({ name, ...other }) => {
                return <PasswordField
                  key={name}
                  {...other}
                  {...getFieldProps(name)}
                  error={errors[name] && touched[name]}
                  helperText={errors[name] && touched[name] && errors[name]}
                />
              })
            }
          </Stack>
        </CardContent>
        <CardActions>
          <Button type="submit" variant="contained" color="primary">
            Chnage Password
          </Button>
        </CardActions>
      </Card>
    </form>
  </Page>
}
export default ChangePassword


const PasswordField = ({ ...props }) => {
  const [show, setShow] = useState(false);
  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return <TextField
    {...props}
    type={show ? "text" : "password"}
    InputProps={{
      endAdornment: <InputAdornment position="end">
        <IconButton
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          <Icon>
            {show ? 'visibility' : 'visibility_off'}
          </Icon>
        </IconButton>
      </InputAdornment>
    }}
  />
}