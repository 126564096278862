// material
import {
  Fab, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip
} from '@mui/material';
import { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ScrollTop from '../components/ScrollTop';
import ApplicationContext from '../context';

export default function DashboardLayout() {
  const { logout } = useContext(ApplicationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  return (
    <Stack spacing={2}>
      <div id="back-to-top-anchor" />
      <Tooltip title={'Menu'} sx={{}}>
        <IconButton
          sx={{
            backgroundColor: 'success.main', position: 'fixed',
            top: '16px', left: '16px', zIndex: 1000
          }}
          onClick={handleClick}>
          <Icon>menu</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={e => {
          handleClose()
          navigate('/')
        }}>
          <ListItemIcon>
            <Icon>send</Icon>
          </ListItemIcon>
          <ListItemText>Send Invoice</ListItemText>
        </MenuItem>
        <MenuItem onClick={e => {
          handleClose()
          navigate('/list-invoice')
        }}>
          <ListItemIcon>
            <Icon>receipt</Icon>
          </ListItemIcon>
          <ListItemText>Invoice List</ListItemText>
        </MenuItem>
        <MenuItem onClick={e => {
          handleClose()
          navigate('/setup')
        }
        }>
          <ListItemIcon>
            <Icon>person</Icon>
          </ListItemIcon>
          <ListItemText>Company Details</ListItemText>
        </MenuItem>
        <MenuItem onClick={e => {
          handleClose()
          navigate('/change-password')
        }}>
          <ListItemIcon>
            <Icon>password</Icon>
          </ListItemIcon>
          <ListItemText>Change Password</ListItemText>
        </MenuItem>
        <MenuItem onClick={async e => {
          logout()
          handleClose()
          navigate('/')
        }}>
          <ListItemIcon>
            <Icon>logout</Icon>
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
      <Outlet />
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <Icon>keyboard_arrow_up</Icon>
        </Fab>
      </ScrollTop>
    </Stack>
  );
}
